import { object } from "prop-types";
import { ResponsiveImage } from "@quintype/components";
import React, { useEffect, useState } from "react";
import { Link } from "@quintype/components";

import ScrollSnap from "../../../molecules/ScrollSnap";
import TriangleArrow from "../../../atoms/icons/triangle-arrow";

import "./slideshow-element.m.css";

const Slideshow = ({ element }) => {
  const handlePageView = () => {
    if (window?.dataLayer) {
      window.dataLayer.push({
        event: "slideShow"
      });
    }
  };

  const [arrowTop, setArrowTop] = useState("");

  const calculateArrowTop = () => {
    const ImageHeight = document.querySelector("#slideshow-image-wrapper")?.clientHeight;
    const arrowHeight = window.innerWidth < 768 ? 25 : 45;
    setArrowTop(`${ImageHeight / 2 - arrowHeight}px`);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      calculateArrowTop();
      window.addEventListener("resize", calculateArrowTop);
      return () => window.removeEventListener("resize", calculateArrowTop);
    }
  }, []);

  const children = element?.["story-elements"]?.map((item, index) => (
    <div key={index}>
      <div id="slideshow-image-wrapper">
        <figure className="blank-story-image" styleName="qt-image-16x9">
          {item?.hyperlink ? (
            <Link href={item?.hyperlink}>
              <ResponsiveImage
                slug={decodeURIComponent(item["image-s3-key"])}
                metadata={item["image-metadata"]}
                aspectRatio={[16, 9]}
                defaultWidth={480}
                widths={[250, 480, 640]}
                imgParams={{ auto: ["format", "compress"], fmt: "webp" }}
                alt={item["image-attribution"] || item.title || ""}
              />
            </Link>
          ) : (
            <ResponsiveImage
              slug={decodeURIComponent(item["image-s3-key"])}
              metadata={item["image-metadata"]}
              aspectRatio={[16, 9]}
              defaultWidth={480}
              widths={[250, 480, 640]}
              imgParams={{ auto: ["format", "compress"], fmt: "webp" }}
              alt={item["image-attribution"] || item.title || ""}
            />
          )}
        </figure>
      </div>
      {item.title && (
        <div styleName="scroll-caption-wrapper">
          <figcaption styleName="caption" dangerouslySetInnerHTML={{ __html: item.title }} />
          {item["image-attribution"] && (
            <span styleName="attribute" dangerouslySetInnerHTML={{ __html: item["image-attribution"] }} />
          )}
        </div>
      )}
    </div>
  ));

  return (
    <div styleName="wrapper">
      <ScrollSnap
        leftArrow={
          <div className="arrow-wrapper" style={{ top: arrowTop, left: "0px", position: "absolute" }}>
            <TriangleArrow />
          </div>
        }
        rightArrow={
          <div className="arrow-wrapper" style={{ top: arrowTop, right: "0px", position: "absolute" }}>
            <TriangleArrow />
          </div>
        }
        handleNextClick={handlePageView}
      >
        {children}
      </ScrollSnap>
    </div>
  );
};

Slideshow.propTypes = {
  element: object
};

export default Slideshow;
