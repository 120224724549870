import React, { forwardRef, useState, useEffect } from "react";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { object, number, bool } from "prop-types";
import {
  format,
  startOfDay,
  endOfDay,
  startOfYear,
  endOfYear,
  startOfWeek,
  endOfWeek,
  isSameISOWeek,
  startOfMonth,
  endOfMonth
} from "date-fns";
import DatePicker from "react-datepicker";

import StorySideBar from "../story-sidebar";
import AuthorDefaultLogo from "../../atoms/author-default-img";
import StoryDate from "../../atoms/story-date";
import Separator from "../../atoms/separator";
import { AdContainer } from "../../ads";

import SocialMediaShare from "../../molecules/social-media-share";
import { getAdvancedSearchStories } from "../../helper/api";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import MobileBottomWidgets from "../mobile-bottom-widgets";

import "./horoscope.m.css";
import useAdType from "../../helper/useAdType";
import { ResponsiveImage } from "@quintype/components";

const ZodiacIcon = ({ slug }) => (
  <div styleName="zodiac-icon-wrapper">
    <ResponsiveImage slug={slug} />
  </div>
);
const Horoscopes = [
  {
    icon: <ZodiacIcon slug={"deccanherald/2024-12-11/9hltt306/aries"} />,

    name: "Aries",
    key: "aries"
  },
  {
    icon: <ZodiacIcon slug={"deccanherald/2024-12-11/ladnzbkl/taurus"} />,

    name: "Taurus",
    key: "taurus"
  },
  {
    icon: <ZodiacIcon slug={"deccanherald/2024-12-11/ebzwuip7/gemini"} />,

    name: "Gemini",
    key: "gemini"
  },
  {
    icon: <ZodiacIcon slug={"deccanherald/2024-12-11/lgfj9a8o/cancer"} />,

    name: "Cancer",
    key: "cancer"
  },
  {
    icon: <ZodiacIcon slug={"deccanherald/2024-12-11/ers85s7w/leo"} />,

    name: "Leo",
    key: "leo"
  },
  {
    icon: <ZodiacIcon slug={"deccanherald/2024-12-11/aw57ry2x/virgo"} />,

    name: "Virgo",
    key: "virgo"
  },
  {
    icon: <ZodiacIcon slug={"deccanherald/2024-12-11/6i98b58k/libra"} />,

    name: "Libra",
    key: "libra"
  },
  {
    icon: <ZodiacIcon slug={"deccanherald/2024-12-11/2sbaac3u/scorpio"} />,

    name: "Scorpio",
    key: "scorpio"
  },
  {
    icon: <ZodiacIcon slug={"deccanherald/2024-12-11/3x4xvtt0/sagittarius"} />,

    name: "Sagittarius",
    key: "sagittarius"
  },
  {
    icon: <ZodiacIcon slug={"deccanherald/2024-12-11/meu45yjd/capricon"} />,

    name: "Capricorn",
    key: "capricorn"
  },
  {
    icon: <ZodiacIcon slug={"deccanherald/2024-12-11/xkp8zlbi/aquarius"} />,

    name: "Aquarius",
    key: "aquarius"
  },
  {
    icon: <ZodiacIcon slug={"deccanherald/2024-12-11/h0ek706t/pisces"} />,

    name: "Pisces",
    key: "pisces"
  }
];

const filterOptions = [
  {
    name: "Daily",
    value: "horoscope-daily"
  },
  {
    name: "Weekly",
    value: "horoscope-weekly"
  },
  // {
  //   name: "Monthly",
  //   value: "horoscope-monthly"
  // },
  {
    name: "Yearly",
    value: "horoscope-yearly"
  }
];
const getHoroscopeFilterTag = (tags) => {
  return tags.find(
    (tag) =>
      tag?.slug === "horoscope-daily" ||
      tag?.slug === "horoscope-weekly" ||
      tag?.slug === "horoscope-monthly" ||
      tag?.slug === "horoscope-yearly"
  );
};

const HoroscopeStoryTemplate = ({ story, index, isWebView = false }) => {
  const dispatch = useDispatch();

  const storyFilter = getHoroscopeFilterTag(story.tags);

  const publishedDate = get(story, ["published-at"]);

  const storyPublishedDate = format(new Date(publishedDate), "dd MMMM yyyy, HH:mm");
  const defaultStoryDate = publishedDate ? new Date(publishedDate) : new Date();

  const [filterType, setFilterType] = useState(storyFilter?.slug);
  const [filterDate, setFilterDate] = useState(defaultStoryDate);

  const [filterOption, setFilterOption] = useState(storyFilter.slug);
  const [toggleSharing, setToggleSharing] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [updateStory, setUpdateStory] = useState(story);

  const author = get(story, ["authors", "0"], {});
  const authorImg = author["avatar-url"] || author["avatar-s3-key"];

  const preventKeyboardHandler = (e) => (e.target.readOnly = true);

  const [isMobile, setMobile] = useState(false);
  const adType = useAdType();

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    if (filterType === "horoscope-daily") {
      setUpdateStory((prevState) => ({
        ...prevState,
        "hero-image-s3-key":
          "/deccanherald-training/2023-07/ac09217c-bfbe-430c-ab69-54634324ffdb/horoscope_icon__1_.png"
      }));
    } else if (filterType === "horoscope-weekly") {
      setUpdateStory((prevState) => ({
        ...prevState,
        "hero-image-s3-key":
          "/deccanherald-training/2023-07/ac09217c-bfbe-430c-ab69-54634324ffdb/horoscope_icon__1_.png"
      }));
    } else if (filterType === "horoscope-monthly") {
      setUpdateStory((prevState) => ({
        ...prevState,
        "hero-image-s3-key":
          "/deccanherald-training/2023-07/ac09217c-bfbe-430c-ab69-54634324ffdb/horoscope_icon__1_.png"
      }));
    } else if (filterType === "horoscope-yearly") {
      setUpdateStory((prevState) => ({
        ...prevState,
        "hero-image-s3-key":
          "/deccanherald-training/2023-07/ac09217c-bfbe-430c-ab69-54634324ffdb/horoscope_icon__1_.png"
      }));
    }
  }, [filterType]);

  const getHoroscopeCard = (item, index) => {
    const text = story?.metadata?.[item.key] || "";
    return (
      <div styleName="horoscope-item">
        <div styleName="icon-wrapper">
          <div styleName="horoscope-icon">{item.icon}</div>
        </div>

        <div styleName="horoscope-text">
          <div styleName="horoscope-name">{item.name}</div>
          <div styleName="horoscope-description">{text}</div>
          <div styleName="horoscope-date-share">
            <StoryDate story={story} />
            <div styleName="share-wrapper">
              <div onClick={() => setToggleSharing(toggleSharing === index ? undefined : index)}>
                <SvgIconHandler type="share2" height="24px" width="24px" />
              </div>
              <div styleName="share-options">{toggleSharing === index && <SocialMediaShare story={updateStory} />}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const navigateToPage = (url) => global.app.navigateToPage(dispatch, url);

  const getBeforeAndAfterDates = (filterType, dateValue) => {
    switch (filterType) {
      case "horoscope-daily": {
        const startDate = startOfDay(dateValue);
        const endDate = endOfDay(dateValue);
        return [Number(startDate), Number(endDate)];
      }
      case "horoscope-weekly": {
        const startDate = startOfWeek(dateValue);
        const endDate = endOfWeek(dateValue);
        return [Number(startDate), Number(endDate)];
      }
      case "horoscope-monthly": {
        const startDate = startOfMonth(dateValue);
        const endDate = endOfMonth(dateValue);
        return [Number(startDate), Number(endDate)];
      }
      case "horoscope-yearly": {
        const startDate = startOfYear(dateValue);
        const endDate = endOfYear(dateValue);
        return [Number(startDate), Number(endDate)];
      }
    }
  };

  const getFilterStories = (filterType, publishedAfter, publishedBefore) => {
    if (filterType === "horoscope-yearly") {
      navigateToPage("/specials/yearly-horoscope-2024-check-horoscope-for-all-sun-signs-2829011");
    } else {
      const params = {
        "tag-name": filterType,
        "published-after": publishedAfter,
        "published-before": publishedBefore
      };

      getAdvancedSearchStories(0, 1, params).then((items) => {
        if (items.length) {
          navigateToPage(`/${items[0]?.slug}`);
        } else {
          setShowMessage(true);
        }
      });
    }
  };

  const handleDateFilter = (filterType) => (date) => {
    setFilterDate(date);
    const values = getBeforeAndAfterDates(filterType, date);
    getFilterStories(filterType, values[0], values[1]);
  };

  const handleTabClick = (type) => {
    setFilterType(type);
    handleDateFilter(type)(new Date());
  };

  const setFilterDropDown = (e) => {
    setFilterOption(e.target.value);
    handleTabClick(e.target.value);
  };

  const DateInput = forwardRef(({ onClick, startDate, endDate }, ref) => {
    return (
      <div styleName="datepicker-weekly" onClick={onClick} ref={ref}>{`${format(startDate, "dd/MM/yyyy")} - ${format(
        endDate,
        "dd/MM/yyyy"
      )}`}</div>
    );
  });

  const renderDateFilter = (filterType) => {
    switch (filterType) {
      case "horoscope-daily":
        return (
          <DatePicker
            selected={filterDate}
            onChange={handleDateFilter("horoscope-daily")}
            className="date-picker"
            dateFormat="dd MMM yyyy"
            maxDate={new Date()}
            showIcon
            onFocus={preventKeyboardHandler}
          />
        );
      case "horoscope-weekly":
        return (
          <div styleName="weekly-dropdown">
            <DatePicker
              selected={filterDate}
              startDate={startOfWeek(filterDate)}
              maxDate={endOfWeek(new Date())}
              endDate={endOfWeek(filterDate)}
              onChange={handleDateFilter("horoscope-weekly")}
              dayClassName={(date) => {
                isSameISOWeek(date, filterDate) ? "react-datepicker__day--selected" : "";
              }}
              showIcon
              onFocus={preventKeyboardHandler}
              customInput={<DateInput startDate={startOfWeek(filterDate)} endDate={endOfWeek(filterDate)} />}
            />
          </div>
        );
      case "horoscope-monthly":
        return (
          <DatePicker
            selected={filterDate}
            maxDate={endOfMonth(new Date())}
            onChange={handleDateFilter("horoscope-monthly")}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            showIcon
            onFocus={preventKeyboardHandler}
          />
        );
      // case "horoscope-yearly":
      //   return (
      //     <DatePicker
      //       selected={filterDate}
      //       maxDate={endOfYear(new Date())}
      //       onChange={handleDateFilter("horoscope-yearly")}
      //       showYearPicker
      //       dateFormat="yyyy"
      //       showIcon
      //       onFocus={preventKeyboardHandler}
      //     />
      //   );
    }
  };

  return (
    <div styleName="story-wrapper">
      <div styleName="story-content">
        <div styleName="top-filter-section">
          <div styleName="filters">
            <div
              onClick={() => handleTabClick("horoscope-daily")}
              styleName={`custom-button ${filterType === "horoscope-daily" ? "active" : ""}`}
            >
              Daily
            </div>
            <div
              onClick={() => handleTabClick("horoscope-weekly")}
              styleName={`custom-button ${filterType === "horoscope-weekly" ? "active" : ""}`}
            >
              Weekly
            </div>
            {/* <div
              onClick={() => handleTabClick("horoscope-monthly")}
              styleName={`custom-button ${filterType === "horoscope-monthly" ? "active" : ""}`}
            >
              Monthly
            </div> */}
            {/* <div
              onClick={() => handleTabClick("horoscope-yearly")}
              styleName={`custom-button ${filterType === "horoscope-yearly" ? "active" : ""}`}
            >
              Yearly
            </div> */}
          </div>
          <select value={filterOption} onChange={setFilterDropDown} styleName="filter-dropdown">
            {filterOptions.map((item, index) => (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
          <div styleName="date-wrapper">{renderDateFilter(filterType)}</div>
        </div>

        {showMessage ? (
          <div styleName="no-stories">No results available</div>
        ) : (
          <>
            <div styleName="title">{story.headline}</div>

            <div styleName="published-date">Published {storyPublishedDate} IST</div>

            <div styleName="horoscope-wrapper">
              {Horoscopes.map((item, index) => {
                return (
                  <>
                    {getHoroscopeCard(item, index)}
                    {index === 0 ? (
                      <div styleName="ad-wrapper">
                        {isMobile ? (
                          <AdContainer
                            AdClassName="standard-336x280"
                            id={adType === "izooto" ? "div-gpt-ad-1711972030627-0" : "div-gpt-ad-1680156704803-0"}
                            mobile={adType === "izooto" ? "DH_MWeb_AT_Mid_ym" : "DH_MWeb_AT_Mid"}
                            isExternalAdPath={adType === "izooto" ? true : false}
                          />
                        ) : (
                          <AdContainer
                            AdClassName="standard-336x280"
                            id={adType === "izooto" ? "div-gpt-ad-1711971649563-0" : "div-gpt-ad-1680156704803-0"}
                            desktop={adType === "izooto" ? "DH_Desktop_AT_Mid_ym" : "DH_Desktop_AT_Mid"}
                            isExternalAdPath={adType === "izooto" ? true : false}
                          />
                        )}
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>

            <Separator />

            <div styleName="author-name">{author.name}</div>

            <div styleName="author-block">
              <div styleName="author-image">
                {authorImg ? <img src={authorImg} alt="author" /> : <AuthorDefaultLogo width={140} height={140} />}
              </div>
              <div styleName="author-text">
                <span>{author.bio}</span>
              </div>
            </div>
            {!isWebView && (
              <div styleName="ad-wrapper">
                <AdContainer
                  AdClassName="standard-336x280"
                  id="div-gpt-ad-1680156752712-0"
                  mobile="DH_MWeb_HP_Bottom"
                  desktop="DH_Desktop_AT_LB_Bottom"
                />
              </div>
            )}
          </>
        )}
      </div>

      {!isMobile && !isWebView ? (
        <div className="side-story-section hide-mobile">
          <StorySideBar />
        </div>
      ) : null}
      {isMobile && !isWebView ? (
        <div className="hide-desktop">
          <MobileBottomWidgets />
        </div>
      ) : null}
    </div>
  );
};

HoroscopeStoryTemplate.propTypes = {
  story: object,
  index: number,
  isWebView: bool
};

const HoroscopeStory = (props) => {
  return <HoroscopeStoryTemplate story={props.story} index={props.index} isWebView={props.isWebView} />;
};

HoroscopeStory.propTypes = {
  story: object,
  index: number
};

export default HoroscopeStory;
